import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  urlType: string;
  SVMformURL: string;
  // enterpriseId: string ="indhumathi.selvam"
  enterpriseId: string =""
  finalSVMurl: string;
  url: string;
  ospid: string;
  loginDisplay = false;
  constructor(private route: ActivatedRoute, private router: Router,private authService: MsalService, private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.urlType = params['type']
      this.ospid= params['ospid']
      this.enterpriseId = params['enterpriseId']
      this.test();
  })
     
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  test(){
    // debugger;
    
    if(this.urlType == 'osp'){
      this.SVMformURL = environment.SVMformURL;
    }
     
    // const StringBuilder = require("string-builder");
    // const sb = new StringBuilder();
    // sb.append(this.SVMformURL);
    let test = this.SVMformURL + (("?type=" + this.urlType + "&ospid=" + this.ospid))
    console.log('test', test);
    
    // sb.append("?" + this.urlType.ToString()); 
    //console.log(sb.ToString()) ;
    if (this.enterpriseId != '' && this.enterpriseId != undefined && sessionStorage.getItem('ospid')!=null)
    {
        this.finalSVMurl = test + "&searchentid=" + this.enterpriseId;
        //this.finalSVMurl = sb.ToString();
        this.url=this.finalSVMurl;
        console.log("FinalUrl", this.url)
        window.location.href = this.url;
    }  
    
  }

}
