import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
// MSAL related imports
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  SVMformURL: string;

  urlType: string;
  finalSVMurl: any;
  url: any;
  isIframe = false;
  loginDisplay = false;
  ospId:string='';
  enterpriseId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  //MSAL related function

  public login(): void {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (!activeAccount) {
        if (this.authService.instance.getAllAccounts().length > 0) {
          const accounts = this.authService.instance.getAllAccounts();
          this.authService.instance.setActiveAccount(accounts[0]);
        } else {
            this.authService.loginRedirect();
        }
      }
  }

  logout() {
    this.authService.logout();
  }

  checkoutAccount() {
    this.loggedIn = !this.authService.instance.getActiveAccount();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  EncryptText(value: string) { // encrypt api request parameter with aes secretkey

    var key = CryptoJS.enc.Utf8.parse('5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<');
    var iv = CryptoJS.enc.Utf8.parse('!QAZ2WSX#EDC4RFV');
    //var iv = new Uint16Array(16);
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key,
        {
            BlockSize: 128,
            keySize: 256,
            iv: iv,
            key: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
    return encrypted.toString();
}

setLoginDisplay() {
  this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
}


  callRoute(enterpriseId:string){
    let objArray = [{ type: 'osp', ospid: sessionStorage.getItem('ospid'),enterpriseId: this.EncryptText(enterpriseId)}];
      this.router.navigate(['/'], {
      queryParams: {
        type: objArray.map((a) => a.type),
        ospid: objArray.map((b) => b.ospid),
        enterpriseId: objArray.map((c) => c.enterpriseId),
      },
    });
  }

  ngOnInit(): void {
    const result = this.checkUserLoggedIn();
    let url = window.location.href;
    this.ospId = url.split('ospid=')[1];
    if(this.ospId!=='' && this.ospId!==null && this.ospId!==undefined)
    sessionStorage.setItem('ospid',this.ospId);
    console.log(this.ospId);
    // debugger;
    if(result && sessionStorage.getItem('ospid')!=null){
      // alert("user Logged in "+result);
      // this.callRoute(this.getEnterpriseId());
      // this.setLoginDisplay();
      this.redirect()
    }
    else{
    this.login();
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.checkAndSetActiveAccount();
      // this.callRoute(this.getEnterpriseId());
      // this.setLoginDisplay();
      this.redirect();
    })
  }
    
  }

  checkUserLoggedIn(){
    const accounts = this.authService.instance.getAllAccounts();
    return accounts.length>0;

  }

  getEnterpriseId():string{
    const loggedInId = this.authService.instance.getActiveAccount().username.split('@')[0];
    console.log("Logged in user is:",loggedInId)
    return this.EncryptText(loggedInId);
  }

  redirect(){
    // debugger;
    this.enterpriseId = this.getEnterpriseId();
    console.log("Encrypted Id:",this.enterpriseId);
    this.urlType='osp';
    if(this.urlType == 'osp'){
      this.SVMformURL = environment.SVMformURL;
    }
     
    if (this.enterpriseId != '' && this.enterpriseId != undefined && sessionStorage.getItem('ospid')!=null)
    {
        let test = this.SVMformURL + (("?type=" + this.urlType + "&ospid=" + sessionStorage.getItem('ospid')))
        console.log('test', test);
        this.finalSVMurl = test + "&searchentid=" + this.enterpriseId;
        //this.finalSVMurl = sb.ToString();
        this.url=this.finalSVMurl;
        console.log("FinalUrl", this.url)
        window.location.href = this.url;
    }  
    
  }

}
