import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './features/home/home.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // MsalRedirectComponent,
    MsalModule.forRoot( new PublicClientApplication({ 
      auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUri, 
      postLogoutRedirectUri: '',
      navigateToLoginRequestUrl: true
      },
      cache: {
      cacheLocation : "localStorage",
      storeAuthStateInCookie: true, 
      },
      system: {
      loggerOptions: {
      loggerCallback: () => {},
      piiLoggingEnabled: false
      }
      }
      }), { // MSAL Guard Configuration
      interactionType: InteractionType.Redirect, 
      authRequest: {
      scopes: ['user.read']
      },
      loginFailedRoute: "/login-failed" 
      }, { // MSAL Interceptor Configuration
      interactionType: InteractionType.Redirect, 
      protectedResourceMap : new Map<string, Array<string> | 
      null>([
      ["https://graph.microsoft.com/v1.0/me", ["user.read", 
      "profile"]],
      ]),
      })

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor,
      multi: true },
      MsalGuard, MsalService, MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }